import React from "react";

// Customizable Area Start
import {
  Box,
  Checkbox,
  Grid,
  Link,
  ThemeProvider,
  Typography,
  createTheme,
  styled,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPrivacyandTerms = (text:string) => {
    return (
      <Box className="checkBox_agree_Box">
        <Checkbox className="checkcolor" color="primary" />
        <span className="agreeText">{this.handleTranslationChange("agreewith")}</span>
        <Link className="termsLink">{this.handleTranslationChange(text)}</Link>
      </Box>
    );
  };

  renderTypographyMessage = (language:any)=>{
    console.log("called")
    return (
      <Typography 
              className="title">{this.handleCondition(language == 'en' ,`Terms & Condition` , `الشروط والأحكام`)}<br/><br/></Typography>
    )
  }
  
  renderTermsCondsBlock = (path:string,blockContent:any, language:string) =>{
    return(
      <>
       {path === "/TermsConditions" && blockContent ? (
         <Grid
         container spacing={0}>
        <Grid 
        item xs={12} 
        sm={12} className="grid" 
        dir= {this.handleCondition(language=='ar','rtl','ltr')}>
          <Box dir= {this.handleCondition(language=='ar','rtl','ltr')}  
          data-test-id="navigateToSignUpPage_term" onClick={this.navigateToBack}
           sx={{[theme.breakpoints.up("xs")]:{backgroundImage: `url(${this.state.imgArrow})`, backgroundRepeat:'no-repeat'},[theme.breakpoints.down("xs")]:{ backgroundImage: `url(${this.state.imgArrowSmall})`, backgroundRepeat:'no-repeat'}}} className= {language == 'ar' ? "arrowButtonEn arrowButton" : "arrowButton"} >
          </Box>
          <Box className="mainBox"
           dir= {this.handleCondition(language=='ar','rtl','ltr')}>
            <Box className="innerBox"
             dir= {this.handleCondition(language=='ar','rtl','ltr')} >
              {this.renderTypographyMessage(language)}
              <Typography dir= {this.handleCondition(language=='ar','rtl','ltr')}  
              dangerouslySetInnerHTML={{ __html: blockContent }} className="text">
              </Typography>
              {this.renderPrivacyandTerms("termscondition" )}
            </Box>
            
          </Box>
        </Grid>
      </Grid>
     ):null}
      </>
    )
  }
  renderPrivacyPolicyBlock = (path:string,blockContent:any, language:string) =>{
    return(
      <>
      {path === "/PrivacyPolicy" && blockContent ? (
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} className="grid" dir= {this.handleCondition(language=='ar','rtl','ltr')} >
        <Box dir= {this.handleCondition(language=='ar','rtl','ltr')} data-test-id="navigateToSignUpPage_privacy" onClick={this.navigateToBack} sx={{[theme.breakpoints.up("xs")]:{backgroundImage: `url(${this.state.imgArrow})`, backgroundRepeat:'no-repeat'},[theme.breakpoints.down("xs")]:{ backgroundImage: `url(${this.state.imgArrowSmall})`, backgroundRepeat:'no-repeat'}}} className= {language == 'ar' ? "arrowButtonEn arrowButton" : "arrowButton"} >
          </Box>
          <Box className="mainBox" dir= {this.handleCondition(language=='ar','rtl','ltr')} >
            <Box className="innerBox" dir= {this.handleCondition(language=='ar','rtl','ltr')} >
              <Typography className="title">{ this.handleCondition(language == 'en' , 'Privacy Policy', 'سياسة الخصوصية')}<br/><br/></Typography>
              
                
              <Typography dir= {this.handleCondition(language=='ar','rtl','ltr')} dangerouslySetInnerHTML={{ __html: blockContent }} className="text">
              </Typography>
              {this.renderPrivacyandTerms("privacypolicy")}
            </Box>
          </Box>
        </Grid>
        </Grid>
      ) : null}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const pathName = window.location.pathname;
    const selectedLang= this.state.apiEndPoint;
    return (
      
      <MainWrapper data-test-id="MainWrapper">
        <ThemeProvider theme={theme} >
          {this.renderTermsCondsBlock(pathName,this.state.contentTermsConds,selectedLang)}
          {this.renderPrivacyPolicyBlock(pathName,this.state.contentPrivacyPolicy, selectedLang)}
        </ThemeProvider>
      </MainWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const MainWrapper = styled(Box)(({ theme, }) => ({
  "& .grid": {
  },
  "& .mainBox": {
    padding: 0,
    borderRadius: 0,
    display: "flex",
    height: "100vh",
    flexDirection: "column",
  },
  "& .innerBox": {
    display: "flex",
    marginTop:'43px',
    marginLeft: "96px",
    marginRight: "96px",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]:{
      marginTop:'16px',
      marginLeft: "16px",
      marginRight: "16px",

    }
  },
  
  "& .agreeText": {
    paddingRight: "8px",
    fontFamily: "DIN Next LT Arabic Regular",
    fontSize: '16px',
    fontWeight: 400,
    paddingBottom: "3px",
  },

  "& .termsLink": {
    fontFamily: "DIN Next LT Arabic Regular",
    fontSize: '16px',
    fontWeight: 400,
    paddingBottom: "3px",
  },


  "& .arrowButton": {
    width:'32px',
    height:'32px',
    marginTop:'32px',
    marginLeft:"96px",
    marginRight:'96px',
    cursor: 'pointer',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '&:hover': {
      transition: "0.7s"
    },
  
    [theme.breakpoints.down("xs")]:{
      width:'24px',
      height:'24px',
      marginTop:'55px',
      marginLeft:"32px",
      cursor: 'pointer',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      '&:hover': {
        transition: "0.7s"
      },
    }
  },
  "& .arrowButtonEn": {
    rotate:'180deg',
  },
  "& .title": {
    color:"#0F172A",
    fontFamily: 'DIN Next LT Arabic Bold',
    fontSize: '36px',
    fontWeight: 700,
    lineHeight: '44px',
    letterSpacing: '-1%',
    [theme.breakpoints.down('xs')]:{
      color:"#0F172A",
      fontFamily: 'DIN Next LT Arabic Bold',
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '32px',
      letterSpacing: '-0.5%',
      marginTop: '8px'

    }
  },
  "& .text":{
    color:"#1E293B",
    fontFamily: 'DIN Next LT Arabic Regular',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '26px',
    [theme.breakpoints.down("xs")]:{
      color:"#1E293B",
    fontFamily: 'DIN Next LT Arabic Regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    marginTop: '-16px'

    }
  },
  "& checkBox_Box": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
  
  },
  "& .checkBox_agree_Box": {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: "100px"
  },
   "& .checkBox_agree_style": {
      color:"#0F172A",
      fontFamily: "DIN Next LT Arabic Regular",
      fontSize: "16px",
      fontWeight: "400",
      fontStyle: 'normal',
      lineHeight: "24px",
      textAlign: "left",
    },
    "& .checkBox_terms_Button_style": {
     textTransform:'none',
     backgroundColor:'transparent'
    },
    "& .checkBox_terms_style": {
      color:'#1948CB',
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize: "16px",
      fontWeight: "700",
      fontStyle: 'normal',
      lineHeight: "24px",
      textAlign: "left",
    },
    "& .root": {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    "& .icon": {
      marginLeft: '-9px',
      border:'1px solid #64748B',
      borderRadius: 6,
      width: 20,
      height: 20,
      
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#FFFFFF',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    "& .checkedIcon": {
      backgroundColor: '#132E79',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 20,
        height: 20,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    },
}));

export const TermsConditionsWeb = withTranslation()(TermsConditions);
// Customizable Area End
