import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, googleIcon } from "./assets";
import React, { ChangeEvent } from "react";
import { toast } from "react-toastify";
import googleController from "../../social-media-account/src/GoogleWebController"
interface UserDetails {
  first_name:string;
  last_name:string;
  email:string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  i18n?: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  inputRef: React.RefObject<HTMLInputElement>;
  showPassword: boolean;
  popupForPassword: boolean;
  open: boolean;
  codeBox: boolean;
  searchData: string;
  passwordErrorShow: string;
  passwordErrorColor: string;
  passwordArr: {
    isConsist: string[],
    text: string
  }[];
  customError: string;
  initialValues: {
    firstName: string,
    lastName: string,
    email: string,
    id: string,
    contact: string,
    service: string,
    country: string,
    password: string,
    filename: string,
    file: string,
    agree: boolean,
    phonecode: string,
  };
  signupModal: boolean;
  authToken:string;
  googleLoginStatus:boolean;
  imgGoogleIcon:string;
  loading:boolean;
  pageLoading:boolean;
  openDialogue:boolean;
  showSuccessMessage:boolean;
  googleLoginMsg: string;
  showSuccessMessageProfile:boolean;
  showSuccessMessageUpdate:boolean;
  googleClientId:string;
  selectWidth:number,
  selectRef: React.RefObject<HTMLButtonElement>;
  currentLanguage: string;
  formattedDate: Date;
  calendarOpen:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  signupApiCallId: string = "";
  getUserProfileCallId:string = "";
  createGoogleAccountAPI:string = "";
  userDetailUpdateApi:string="";
  protected formikRef: React.RefObject<any> = React.createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      inputRef: React.createRef(),
      showPassword: false,
      popupForPassword: false,
      open: false,
      codeBox: false,
      searchData: "",
      passwordErrorShow: "",
      passwordErrorColor: "",
      customError: "",
      initialValues: this.handleCondition(
        this.handleCondition(
          localStorage.getItem("privacy"),
          JSON.parse(localStorage.getItem("privacy") || "false"),
          ""
        ),
        this.handleCondition(
          localStorage.getItem("formValues"),
          JSON.parse(localStorage.getItem("formValues") || "{}"),
          ""
        ),
        {
          firstName: "",
          lastName: "",
          email: "",
          id: "none",
          contact: "",
          service: "none",
          country: "Oman",
          password: "",
          filename: "",
          file: "",
          agree: false,
          phonecode: "968",
        }
      ),
      passwordArr: [
        {
          isConsist: [this.props.i18n?.t('weakPassword'), this.props.i18n?.t('mediumPassword'), this.props.i18n?.t('strongPassword')],
          text: this.props.i18n?.t('weakPassword'),
        },
        {
          isConsist: [this.props.i18n?.t('mediumPassword'), this.props.i18n?.t('strongPassword')],
          text: this.props.i18n?.t('mediumPassword'),
        },
        {
          isConsist: [this.props.i18n?.t('strongPassword')],
          text: this.props.i18n?.t('strongPassword'),
        },
      ],
      signupModal: false,
      authToken:"",
      googleLoginStatus:false,
      imgGoogleIcon:googleIcon,
      loading:false,
      pageLoading:false,
      openDialogue:false,
      showSuccessMessage:false,
      googleLoginMsg: "",
      showSuccessMessageProfile:false,
      showSuccessMessageUpdate:false,
      googleClientId:configJSON.clientID,
      selectWidth:0,
      selectRef:React.createRef<HTMLButtonElement>(),
      currentLanguage: this.normalizeLanguage(this.props.i18n?.language) || "en",
      formattedDate: new Date(),
      calendarOpen: false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      
      const sessionData = message.getData(getName(MessageEnum.SessionResponseData));
      if (sessionData) {
        this.setState({
          authToken : sessionData.token,
          googleLoginStatus:true
        });
        if(sessionData.message=="coming from login page"){
          this.setState({
            openDialogue:false,
            showSuccessMessage:false,
            showSuccessMessageProfile:false,
            showSuccessMessageUpdate:true,
            
            
        })
        this.getUserDetails(sessionData.token)
        }
      }
    }  
    
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createGoogleAccountAPI !== "" &&
      this.createGoogleAccountAPI ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
     
      this.GoogleLoginResponse(message)
    }
    this.receive1(from, message);
    this.receive2(from, message);
    this.receive3(from, message);
    this.receive4(from, message);
    this.receive5(from, message);
    this.receive6(from, message);

    // Customizable Area End
  }

  // Customizable Area Start
    async componentDidMount() {
      this.calculateSelectWidth();
      window.addEventListener('resize', this.handleResize);
  }
  
  async componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
  }
  
  handleResize = () => {
      this.calculateSelectWidth();
  };
  
  calculateSelectWidth = () => {
      const selectRef = this.state.selectRef.current;
      if (selectRef) {
          const width = selectRef.clientWidth;
          this.setState({ selectWidth: width });
      }
  };
  handleSigninClick = () => {
    const goToDashboard = new Message(getName(MessageEnum.NavigationMessage));
    goToDashboard.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    goToDashboard.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(goToDashboard);

  };

  handlePhoneChange = (phone: string) => {
      this.setState({phone})
  }
  async receive1(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.validationApiCallId) {
      this.arrayholder = responseJson.data;

      if (this.arrayholder && this.arrayholder.length !== 0) {
        let regexData = this.arrayholder[0];
        this.handleCondition(regexData.password_validation_rules, this.setState({
          passwordHelperText: regexData.password_validation_rules,
        }), "")
        this.passwordReg = this.handleCondition(regexData.password_validation_regexp, new RegExp(regexData.password_validation_regexp), "")
        this.emailReg = this.handleCondition(regexData.email_validation_regexp, new RegExp(regexData.email_validation_regexp), "")
      }
    }
    
  }
  async receive2(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode,
        });
      }
    }
  }
  async receive3(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.signupApiCallId) {
      if(responseJson && !responseJson.errors) {
        this.signupTruePart();
      }
      if(responseJson && responseJson.errors) {
        if(Object.keys(responseJson.errors[0]).includes("phone_number")) {
          toast.error(this.props.i18n?.t("phnAlreadyRegis"))
        }else
        Object.values(responseJson.errors[0]).forEach((item:any) => toast.error(item)); 
      }
    }
  }
  async receive4(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId === this.createAccountApiCallId) {
      if (responseJson && !responseJson.errors) {
        const msg: Message = new Message(
          getName(MessageEnum.AccoutResgistrationSuccess)
        );
        msg.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
        );
        this.send(msg);
    } else if (responseJson?.errors) {
        if (Object.keys(responseJson.errors[0]).includes("phone_number")) {
            toast.error(this.props.i18n?.t("phnAlreadyRegis"));
        } else {
            Object.values(responseJson.errors[0]).forEach((item: any) =>
                toast.error(item)
            );
        }
        this.parseApiErrorResponse(responseJson);
    } else {
        console.error("responseJson is undefined or null:", responseJson);
    }
    
      this.parseApiCatchErrorResponse(errorReponse);
    }
    
  }
  async receive5(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId === this.getUserProfileCallId) {
      
      if (!responseJson.errors) {
       
        this.getResponseUserProfileData(responseJson.data.attributes);
        }else{
          this.parseApiErrorResponse(responseJson);
          if(Object.keys(responseJson.errors[0]).includes("phone_number")) {
            toast.error(this.props.i18n?.t("phnAlreadyRegis"))
          }else
          
          Object.values(responseJson.errors[0]).forEach((item:any) => toast.error(item)); 
          
        }
        this.parseApiCatchErrorResponse(errorReponse);
      
    }
    
  }
  async receive6(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId === this.userDetailUpdateApi) {
      if (!responseJson.errors) {
       
        this.setState({signupModal: true})
          }else{
            this.parseApiErrorResponse(responseJson);
            if(Object.keys(responseJson.errors[0]).includes("phone_number")) {
              toast.error(this.props.i18n?.t("phnAlreadyRegis"))
            }else
            
            Object.values(responseJson.errors[0]).forEach((item:any) => toast.error(item)); 
          }
          this.parseApiCatchErrorResponse(errorReponse);
      
      
    }
    
  }
  signupTruePart = () => {
    this.setState({
      signupModal: true
    })
  }

  handleCondition = (condition: boolean | string | undefined | null, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart;
  };

  handleClick = () => {
    return this.state.inputRef.current
      ? this.state.inputRef.current.click()
      : "";
  };

  handleTogglePassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  setCustomError = (msgKey: string) => {
    this.setState({ customError: msgKey });
  };

  calculateStrength = (password: string) => {
    const strength = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /\d/.test(password),
      special: /[^a-zA-B0-9]+/.test(password),
    };
    const strengthScore = Object.values(strength).filter(Boolean).length;
    const { color, text } = this.handleCondition(
      strengthScore <= 3,
      { color: "red", text: this.props.i18n?.t('weakPassword') },
      this.handleCondition(
        strengthScore === 4,
        { color: "orange", text: this.props.i18n?.t('mediumPassword') },
        this.handleCondition(
          strengthScore === 5,
          { color: "green", text: this.props.i18n?.t('strongPassword') },
          { color: "#E2E8F0", text: "" }
        )
      )
    );
    this.setState({ passwordErrorShow: text, passwordErrorColor: color });
  };

  handleSignupModalClose = () => {
    this.setState({signupModal: false},
      
      this.handleSigninClick
      );
  }

  handlepasswordPopup = () => {
    this.setState({ popupForPassword: !this.state.popupForPassword });
  };

  handleOpen = () => {
    this.setState({open: !this.state.open, codeBox: false});
  }
  handleCodeBox = () => {
    this.setState({codeBox: !this.state.codeBox, open: false})
  }

  openPopupClose = () => {
   this.setState({open: false})
  }

  getPasswordArr = () => {
    return [
      {
        isConsist: [
          this.props.i18n?.t("weakPassword"),
          this.props.i18n?.t("mediumPassword"),
          this.props.i18n?.t("strongPassword"),
        ],
        text: this.props.i18n?.t("weakPassword"),
      },
      {
        isConsist: [
          this.props.i18n?.t("mediumPassword"),
          this.props.i18n?.t("strongPassword"),
        ],
        text: this.props.i18n?.t("mediumPassword"),
      },
      {
        isConsist: [this.props.i18n?.t("strongPassword")],
        text: this.props.i18n?.t("strongPassword"),
      },
    ];
  };
  
  handleSearchChange = (event: ChangeEvent<HTMLInputElement> | {target: {value: string}}) => {
    this.setState({ searchData: event.target.value });
  };
  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  handleNavigation=(path:string, values:object)=>{
    localStorage.setItem("formValues", JSON.stringify(values));
    localStorage.setItem("privacy", JSON.stringify(true));
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleSignupClick = (values: {
    firstName: string;
    lastName: string;
    email: string;
    id: string;
    contact: string;
    service: string;
    country: string;
    password: string;
    filename: string;
    file: string;
    agree: boolean;
    phonecode: string;
  }) => {
    const formData = new FormData();
    formData.append("data[type]", "email_account");
    formData.append("data[attributes][first_name]", values.firstName);
    formData.append("data[attributes][last_name]", values.lastName);
    formData.append("data[attributes][email]", values.email);
    formData.append("data[attributes][id_proof]", values.id);
    formData.append("data[attributes][id_proof_expire_date]", this.formatDate(this.state.formattedDate));
    formData.append("data[attributes][document]", values.file);
    formData.append("data[attributes][phone_number]", values.contact);
    formData.append("data[attributes][country_code]", values.phonecode);
    formData.append("data[attributes][service_type]", values.service);
    formData.append("data[attributes][country]", values.country);
    formData.append("data[attributes][password]", values.password);

    const header = {
      contentType: configJSON.contentTypeFormData,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.signupApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.signupApiEndPoint}?language=${this.props.i18n?.language}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );
    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    } else if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    } else if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number:
        "+" + this.state.countryCodeSelected + this.state.phone,
    };
    const data = {
      type: "email_account",
      attributes: attrs,
    };
    const httpBody = {
      data: data,
      token: this.state.otpAuthToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getValidations() {
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    this.validationApiCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField,
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnSignUpProps = {
    onPress: () => this.createAccount(),
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy(),
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition(),
  };

  txtInputEmailWebPrpos: any = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      this.txtInputEmailPrpos.value = text;
    },
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address",
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps: any = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      this.txtPhoneNumberProps.value = text;
    },
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos: any = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      this.txtInputLastNamePrpos.value = text;
    },
  };

  txtInputFirstNamePrpos: any = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      this.txtInputFirstNamePrpos.value = text;
    },
  };

  txtInputConfirmPasswordProps: any = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  txtInputPasswordProps: any = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };
  getUserDetails(token:string){
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserDetailsApi
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "token": token
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  getResponseUserProfileData(response:UserDetails){
    this.setState({
      initialValues: {
        firstName: response.first_name || "",
        lastName: response.last_name || "",
        email: response.email || "",
        id: "none",
        contact: "",
        service: "none",
        country: "Oman",
        password: "",
        filename: "",
        file: "",
        agree: false,
        phonecode: "968",
      },
    });


    }
    handleLoginSuccess = (response:any) => {
      this.googleLogIn(false,response.credential)
    };
  normalizeLanguage = (lang: string) => {
    if (!lang) return "en";
    return lang.split("-")[0];
  };
  
    handleLoginFailure = () => {
    };
  
    googleLogIn = (isRegistration: boolean, credential: string) => {
      const self = this;
  
      googleController.googleLogIn(this, credential)?.then(
        function() {
          self.stopLoading();
          runEngine.debugLog("User SIGNED IN.");
        },
        function(error: { error: string }) {
          self.stopLoading();
         
        }
      );
    };
    startLoading = () => {
      this.setState({ loading: true });
    };
  
    stopLoading = () => {
      this.setState({ loading: false });
    };
    googleUserStatusChanged(userInfo: {
      email: string;
      id: string;
      status?: string;
      credential:string;
    }): void {
        this.logInWithSocial(userInfo.email, userInfo.id,userInfo.id,userInfo.credential, this.props);
      
    }
    async logInWithSocial(
      incomingEmail: string,
      incomingPassword: string,
      incomingId: string,
      incomingToken:string,
      props: Props
    ) {
      if (
        !incomingEmail ||
        incomingEmail.length === 0
      
  
      ) {
        runEngine.debugLog("createAccountFromSocial empty info");
        return;
      }
  
      this.startLoading();
      const header = {
        "Content-Type": configJSON.urlHeaderTypeJSON
      };
  
      const attributes = {
        type: "google", 
        user_type: "test", 
        access_token : incomingToken,
        email : incomingEmail
      };
      this.setState({email:incomingEmail})
      const httpBodyData = {
        type: "social_account",
        attributes
      };
  
      const httpBody = {
        data: httpBodyData
      };
      const requestMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createGoogleAccountAPI = requestMsg.messageId;
  
      requestMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.googleLoginApiEndPoint}?language=${this.state.currentLanguage}`
      );
  
      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMsg.addData(getName(MessageEnum.NavigationPropsMessage), props);
  
      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postHttpRequest
      );
  
      runEngine.sendMessage(requestMsg.id, requestMsg);
    }
    toDashboard(){
      const dashboard = new Message(getName(MessageEnum.NavigationMessage));
      dashboard.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
      dashboard.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(dashboard);
      
  
    }
    GoogleLoginResponse = (message:any) => {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        if(responseJson.meta.first_time_login){
          this.setState({
            openDialogue:true,
            googleLoginMsg:responseJson.meta.message,
            showSuccessMessageProfile:false,
            showSuccessMessageUpdate:false,
            googleLoginStatus:true,
            authToken : responseJson.meta.token
        })
          this.getUserDetails(responseJson.meta.token)
          
          
        }else{
          this.saveLoggedInUser(responseJson);
        }
        
      } else if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      } else {
        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    SignUpNavProps = (token:string) =>{
      
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'EmailAccountRegistration'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), { token: token})
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
      this.setState({
        openDialogue:true,
        showSuccessMessage:true,
        showSuccessMessageProfile:false,
        showSuccessMessageUpdate:false,
    })
  
    }
    saveLoggedInUser(responseJson: any) {
      
      if (responseJson && responseJson.meta && responseJson.meta.token && responseJson.data.attributes.activated) {
       
        this.navigateToLoginWithProps("Already registered")
        const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
  
        msg.addData(
          getName(MessageEnum.SessionResponseData),
          JSON.stringify(responseJson)
        );
        msg.addData(
          getName(MessageEnum.SessionResponseToken),
          responseJson.meta.token
        );
  
        this.send(msg);
      }else{
        
        this.navigateToLoginWithProps("Already registered")
        
      }
       
      
    }
    handleGoogleSignup=async(values:any)=>{
      let token= this.state.authToken
      let email = values.email;
      let firstName = values.firstName;
      let lastName = values.lastName;
      let currentCountryCode = values.phonecode;
      let phoneNumber = values.contact? values.contact: "";
      let idProof = values.id ;
      let investmentService = values.service;
      let countryName = values.country;
      let documentImgUrl =values.file;
      let header = {
        "token": token
      };
    
      let formData = new FormData();
      formData.append('data[attributes][first_name]',firstName);
      formData.append('data[attributes][last_name]', lastName);
      formData.append('data[attributes][email]', email);
      formData.append('data[attributes][country_code]', currentCountryCode);
      formData.append('data[attributes][phone_number]', phoneNumber);
      formData.append('data[attributes][id_proof]', idProof);
      formData.append("data[attributes][id_proof_expire_date]", this.formatDate(this.state.formattedDate));
      formData.append('data[attributes][service_type]', investmentService);
      formData.append('data[attributes][country]', countryName);
      formData.append("data[attributes][password]", values.password);
      (typeof documentImgUrl!="string")  && formData.append('data[attributes][document]', documentImgUrl);
       
        const requestData = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.userDetailUpdateApi = requestData.messageId;
        requestData.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.userDetailsUpdateApi
        );
  
        requestData.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
  
        requestData.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
  
        requestData.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.apiUpdateUserType
        );
  
        runEngine.sendMessage(requestData.id, requestData);
      
    }
    navigateToLoginWithProps=(msg:string)=>{
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'EmailAccountLoginBlock'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), { Message: msg})
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(message);
    }

    handleCloseDialogue =()=>{
      this.setState({openDialogue:false})
    }

    handleLanguageChange = async (lng: string) => {
      const { i18n } = this.props;
      await i18n.changeLanguage(lng);
      
      this.setState({
        currentLanguage: lng || 'en',
      }, () => {
        if (this.formikRef.current) {
          this.formikRef.current.validateForm();
        }
      });
    };

    formatDate = (date: Date) => {
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // Add 1 because months are 0-indexed
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
  };
    handleDateChange = (newDate:any) => {
      this.setState({ formattedDate: newDate }, this.openCalendar);
    };

    openCalendar = () => {
      this.setState((prevState) => ({ calendarOpen: !prevState.calendarOpen }));
    };

  selectedCountry = (value: string) => {
    return this.handleCondition(this.props.i18n.language === "ar",
      configJSON.countryListAr?.find((item: { value: string, label: string }) => item.value === value)?.label || this.props.i18n?.t("country_not_found"),
      configJSON.countryList?.find((item: { value: string, label: string }) => item.value === value)?.label || this.props.i18n?.t("country_not_found"))
  }
    
  // Customizable Area End
}
